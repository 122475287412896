import React from 'react'
import {useTranslation} from 'react-i18next'

import {LOCALES, setLanguage} from '../config/locales'
import useAuth from '../hooks/useAuth'

const Footer = () => {
  const {t} = useTranslation()
  const {isAuthenticated, setIsAuthenticated} = useAuth()
  const currentYear = new Date().getFullYear()

  const handleLogout = (e) => {
    e.preventDefault()

    localStorage.removeItem('mf-signature')
    setIsAuthenticated(false)
  }

  return (
    <div className="footer">
      {isAuthenticated && (
        <a href="/" className="logout" onClick={handleLogout}>
          {t('logout')}
        </a>
      )}
      <div className="language-switcher">
        {Object.keys(LOCALES).map((lng) => (
          <a
            key={lng}
            href="/"
            className="link"
            onClick={() => setLanguage(lng)}
          >
            {LOCALES[lng].label}
          </a>
        ))}
      </div>
      <div>
        <a className="pfwiki" href="https://pfwiki.in">
          &copy; pfwiki {currentYear}
        </a>
      </div>
    </div>
  )
}

export default Footer
