import React from 'react'
import {useTranslation} from 'react-i18next'
import {SiWebmoney} from 'react-icons/si'
import {Link} from 'react-router-dom'

const Header = () => {
  const {t} = useTranslation()

  return (
    <Link to="/" className="header">
      <SiWebmoney />
      <div className="title">{t('journal')}</div>
    </Link>
  )
}

export default Header
