import React from 'react'
import {useTranslation} from 'react-i18next'

import Footer from './Footer'
import Header from './Header'

const PageNotFound = () => {
  const {t} = useTranslation()

  return (
    <>
      <Header />
      <div className="page-not-found">
        <div>{t('page_not_found')}</div>
      </div>
      <Footer />
    </>
  )
}

export default PageNotFound
