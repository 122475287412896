import {Timestamp, addDoc, collection, getDocs} from 'firebase/firestore'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {BiSearchAlt2} from 'react-icons/bi'

import {db} from '../config/firebase'
import Footer from './Footer'
import Header from './Header'

// Replace with actual data for upload!
const data = [
  {
    place: 'முத்தாண்டிக்குப்பம்',
    name: 'C. கலியபெருமாள் (ஆசிரியர்)',
    value: '500',
    event: 'திருமணம்',
    date: '12-12-2022',
  },
]

const Dashboard = () => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [error, setError] = useState('')
  const [filteredEntries, setFilteredEntries] = useState([])
  const [eventEntries, setEventEntries] = useState([])
  const eventCollectionRef = collection(db, 'pugazh-thenu-marriage')

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value)
  }

  const uploadData = (e) => {
    e.preventDefault()

    data.forEach((entry) => {
      const {place, name, value, event, date} = entry
      addDoc(eventCollectionRef, {
        place,
        name,
        value,
        event,
        date: Timestamp.fromDate(new Date(date)),
      })
        .then((docRef) => {
          console.log('Inserted', eventCollectionRef)
        })
        .catch((e) => {
          console.log('Error', e)
        })
    })
  }

  const filter = (entry) => {
    const name = entry.name.toLowerCase()
    const place = entry.place.toLowerCase()
    const [keyword1, keyword2] = searchText.toLowerCase().split(' ')

    if (!keyword2) return name.includes(keyword1) || place.includes(keyword1)

    if (
      (name.includes(keyword1) && place.includes(keyword2)) ||
      (name.includes(keyword2) && place.includes(keyword1))
    )
      return true

    return false
  }

  useEffect(() => {
    const getEventEntry = async () => {
      try {
        setLoading(true)
        const eventCollectionDocs = await getDocs(eventCollectionRef)
        const entries = eventCollectionDocs.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        setEventEntries(entries)
        setLoading(false)
      } catch (error) {
        setError(error.message)
        setLoading(false)
      }
    }

    getEventEntry()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!eventEntries.length) return
    if (!searchText) return setFilteredEntries(structuredClone(eventEntries))

    const filteredData = eventEntries.filter(filter)
    setFilteredEntries(filteredData)
    // eslint-disable-next-line
  }, [searchText, eventEntries])

  return (
    <>
      <Header />
      <div className="upload-container hidden">
        <button onClick={uploadData} className="upload-button">
          Upload data
        </button>
      </div>
      {/* Search */}
      <div className="search-container">
        <input
          type="text"
          name="search"
          id="search"
          placeholder={t('search_placeholder')}
          onChange={handleSearchTextChange}
        />
        <BiSearchAlt2 className="search-icon" />
      </div>

      {!loading && (
        <div className="count">
          {t('entries_with_count', {count: filteredEntries.length})}
        </div>
      )}

      {/* Entries list */}
      {loading ? (
        <div className="spinner-container">
          <div className="loader" />
        </div>
      ) : (
        <div
          className={`entries ${error ? 'error' : ''}`}
          data-count={filteredEntries.length}
        >
          {error ? (
            <div>{error}</div>
          ) : (
            filteredEntries.map(({id, name, place, value, event}) => (
              <div key={id} className="entry">
                <div className="details">
                  <div className="name">{name}</div>
                  <div className="place">{place}</div>
                  <div className="event">{event}</div>
                </div>
                <div className="value">
                  {isNaN(value) ? value : `₹${Number(value).toLocaleString()}`}
                </div>
              </div>
            ))
          )}
        </div>
      )}

      {/* Footer */}
      <Footer />
    </>
  )
}

export default Dashboard
