import React, {useEffect, useState} from 'react'
import {Route, Routes} from 'react-router-dom'

import Dashboard from './components/Dashboard'
import Login from './components/Login'
import PageNotFound from './components/PageNotFound'
import ProtectedRoute from './components/ProtectedRoute'
import {AuthProvider} from './contexts/authContext'

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  useEffect(() => {
    const signature = localStorage.getItem('mf-signature')

    if (signature === btoa('mf-admin')) setIsAuthenticated(true)
  }, [])

  return (
    <div className="app">
      <AuthProvider value={{isAuthenticated, setIsAuthenticated}}>
        <Routes>
          <Route
            path="/"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthProvider>
    </div>
  )
}

export default App
