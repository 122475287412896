import React, {useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Navigate} from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import Footer from './Footer'
import Header from './Header'

const Login = () => {
  const {t} = useTranslation()
  const {isAuthenticated, setIsAuthenticated} = useAuth()
  const usernameRef = useRef(null)
  const passwordRef = useRef(null)
  const [error, setError] = useState('')

  const handleLogin = (e) => {
    e.preventDefault()

    if (
      usernameRef.current.value !== 'admin' ||
      passwordRef.current.value !== process.env.REACT_APP_ADMIN_PWD
    )
      return setError('Invalid credentials!')

    setError('')
    localStorage.setItem('mf-signature', btoa('mf-admin'))
    setIsAuthenticated(true)
  }

  if (isAuthenticated) return <Navigate to="/" replace />

  return (
    <>
      <Header />
      <div className="login-container">
        <div className="login-form">
          <form>
            {error && <div className="error">{error}</div>}

            <div className="input-group">
              <label htmlFor="username">{t('username')}</label>
              <input
                type="text"
                name="username"
                id="username"
                ref={usernameRef}
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">{t('password')}</label>
              <input
                type="password"
                name="password"
                id="password"
                ref={passwordRef}
              />
            </div>
            <div className="input-group">
              <button type="submit" onClick={handleLogin}>
                {t('login')}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Login
